<template>
    <img src="img/kofiramos.jpg" alt="kofi ramos image" class="object-fit  " />
</template>

<script>
export default{
  name: "MyImage",
}
</script>

<style>

</style>
